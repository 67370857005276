/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyEmbeddableOpenApi } from './CompanyEmbeddableOpenApi';
import {
    CompanyEmbeddableOpenApiFromJSON,
    CompanyEmbeddableOpenApiFromJSONTyped,
    CompanyEmbeddableOpenApiToJSON,
} from './CompanyEmbeddableOpenApi';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { DealCategoryOpenApi } from './DealCategoryOpenApi';
import {
    DealCategoryOpenApiFromJSON,
    DealCategoryOpenApiFromJSONTyped,
    DealCategoryOpenApiToJSON,
} from './DealCategoryOpenApi';
import type { DealGeographyOpenApi } from './DealGeographyOpenApi';
import {
    DealGeographyOpenApiFromJSON,
    DealGeographyOpenApiFromJSONTyped,
    DealGeographyOpenApiToJSON,
} from './DealGeographyOpenApi';
import type { DealIndustryOpenApi } from './DealIndustryOpenApi';
import {
    DealIndustryOpenApiFromJSON,
    DealIndustryOpenApiFromJSONTyped,
    DealIndustryOpenApiToJSON,
} from './DealIndustryOpenApi';
import type { DealLogosOpenApi } from './DealLogosOpenApi';
import {
    DealLogosOpenApiFromJSON,
    DealLogosOpenApiFromJSONTyped,
    DealLogosOpenApiToJSON,
} from './DealLogosOpenApi';
import type { DealOutcomeOpenApi } from './DealOutcomeOpenApi';
import {
    DealOutcomeOpenApiFromJSON,
    DealOutcomeOpenApiFromJSONTyped,
    DealOutcomeOpenApiToJSON,
} from './DealOutcomeOpenApi';
import type { DealSlideDeckOpenApi } from './DealSlideDeckOpenApi';
import {
    DealSlideDeckOpenApiFromJSON,
    DealSlideDeckOpenApiFromJSONTyped,
    DealSlideDeckOpenApiToJSON,
} from './DealSlideDeckOpenApi';
import type { DealStageOpenApi } from './DealStageOpenApi';
import {
    DealStageOpenApiFromJSON,
    DealStageOpenApiFromJSONTyped,
    DealStageOpenApiToJSON,
} from './DealStageOpenApi';
import type { DealTileOpenApi } from './DealTileOpenApi';
import {
    DealTileOpenApiFromJSON,
    DealTileOpenApiFromJSONTyped,
    DealTileOpenApiToJSON,
} from './DealTileOpenApi';
import type { DealWaitingStateOpenApi } from './DealWaitingStateOpenApi';
import {
    DealWaitingStateOpenApiFromJSON,
    DealWaitingStateOpenApiFromJSONTyped,
    DealWaitingStateOpenApiToJSON,
} from './DealWaitingStateOpenApi';

/**
 * 
 * @export
 * @interface DealPublicOpenApi
 */
export interface DealPublicOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealPublicOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DealPublicOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealPublicOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DealPublicOpenApi
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealPublicOpenApi
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DealPublicOpenApi
     */
    description?: string;
    /**
     * 
     * @type {CompanyEmbeddableOpenApi}
     * @memberof DealPublicOpenApi
     */
    company?: CompanyEmbeddableOpenApi;
    /**
     * 
     * @type {Array<DealCategoryOpenApi>}
     * @memberof DealPublicOpenApi
     */
    categories?: Array<DealCategoryOpenApi>;
    /**
     * 
     * @type {DealGeographyOpenApi}
     * @memberof DealPublicOpenApi
     */
    geography?: DealGeographyOpenApi;
    /**
     * 
     * @type {DealStageOpenApi}
     * @memberof DealPublicOpenApi
     */
    stage?: DealStageOpenApi;
    /**
     * 
     * @type {DealOutcomeOpenApi}
     * @memberof DealPublicOpenApi
     */
    outcome?: DealOutcomeOpenApi;
    /**
     * 
     * @type {DealWaitingStateOpenApi}
     * @memberof DealPublicOpenApi
     */
    waitingState?: DealWaitingStateOpenApi;
    /**
     * 
     * @type {DealIndustryOpenApi}
     * @memberof DealPublicOpenApi
     */
    industry?: DealIndustryOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof DealPublicOpenApi
     */
    finalDeadline?: Date;
    /**
     * 
     * @type {number}
     * @memberof DealPublicOpenApi
     */
    minTarget?: number;
    /**
     * 
     * @type {number}
     * @memberof DealPublicOpenApi
     */
    maxTarget?: number;
    /**
     * 
     * @type {number}
     * @memberof DealPublicOpenApi
     */
    minTicket?: number;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof DealPublicOpenApi
     */
    currency?: CurrencyOpenApi;
    /**
     * 
     * @type {number}
     * @memberof DealPublicOpenApi
     */
    investedAmount?: number;
    /**
     * 
     * @type {DealLogosOpenApi}
     * @memberof DealPublicOpenApi
     */
    logos?: DealLogosOpenApi;
    /**
     * 
     * @type {Array<DealTileOpenApi>}
     * @memberof DealPublicOpenApi
     */
    tiles?: Array<DealTileOpenApi>;
    /**
     * 
     * @type {DealSlideDeckOpenApi}
     * @memberof DealPublicOpenApi
     */
    slideDeck?: DealSlideDeckOpenApi;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealPublicOpenApi
     */
    investors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealPublicOpenApi
     */
    termsTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DealPublicOpenApi
     */
    videoYoutubeCode?: string;
}

/**
 * Check if a given object implements the DealPublicOpenApi interface.
 */
export function instanceOfDealPublicOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "published" in value;

    return isInstance;
}

export function DealPublicOpenApiFromJSON(json: any): DealPublicOpenApi {
    return DealPublicOpenApiFromJSONTyped(json, false);
}

export function DealPublicOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPublicOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'published': json['published'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'company': !exists(json, 'company') ? undefined : CompanyEmbeddableOpenApiFromJSON(json['company']),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(DealCategoryOpenApiFromJSON)),
        'geography': !exists(json, 'geography') ? undefined : DealGeographyOpenApiFromJSON(json['geography']),
        'stage': !exists(json, 'stage') ? undefined : DealStageOpenApiFromJSON(json['stage']),
        'outcome': !exists(json, 'outcome') ? undefined : DealOutcomeOpenApiFromJSON(json['outcome']),
        'waitingState': !exists(json, 'waitingState') ? undefined : DealWaitingStateOpenApiFromJSON(json['waitingState']),
        'industry': !exists(json, 'industry') ? undefined : DealIndustryOpenApiFromJSON(json['industry']),
        'finalDeadline': !exists(json, 'finalDeadline') ? undefined : (new Date(json['finalDeadline'])),
        'minTarget': !exists(json, 'minTarget') ? undefined : json['minTarget'],
        'maxTarget': !exists(json, 'maxTarget') ? undefined : json['maxTarget'],
        'minTicket': !exists(json, 'minTicket') ? undefined : json['minTicket'],
        'currency': !exists(json, 'currency') ? undefined : CurrencyOpenApiFromJSON(json['currency']),
        'investedAmount': !exists(json, 'investedAmount') ? undefined : json['investedAmount'],
        'logos': !exists(json, 'logos') ? undefined : DealLogosOpenApiFromJSON(json['logos']),
        'tiles': !exists(json, 'tiles') ? undefined : ((json['tiles'] as Array<any>).map(DealTileOpenApiFromJSON)),
        'slideDeck': !exists(json, 'slideDeck') ? undefined : DealSlideDeckOpenApiFromJSON(json['slideDeck']),
        'investors': !exists(json, 'investors') ? undefined : json['investors'],
        'termsTags': !exists(json, 'termsTags') ? undefined : json['termsTags'],
        'videoYoutubeCode': !exists(json, 'videoYoutubeCode') ? undefined : json['videoYoutubeCode'],
    };
}

export function DealPublicOpenApiToJSON(value?: DealPublicOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'published': value.published,
        'name': value.name,
        'description': value.description,
        'company': CompanyEmbeddableOpenApiToJSON(value.company),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(DealCategoryOpenApiToJSON)),
        'geography': DealGeographyOpenApiToJSON(value.geography),
        'stage': DealStageOpenApiToJSON(value.stage),
        'outcome': DealOutcomeOpenApiToJSON(value.outcome),
        'waitingState': DealWaitingStateOpenApiToJSON(value.waitingState),
        'industry': DealIndustryOpenApiToJSON(value.industry),
        'finalDeadline': value.finalDeadline === undefined ? undefined : (value.finalDeadline.toISOString().substr(0,10)),
        'minTarget': value.minTarget,
        'maxTarget': value.maxTarget,
        'minTicket': value.minTicket,
        'currency': CurrencyOpenApiToJSON(value.currency),
        'investedAmount': value.investedAmount,
        'logos': DealLogosOpenApiToJSON(value.logos),
        'tiles': value.tiles === undefined ? undefined : ((value.tiles as Array<any>).map(DealTileOpenApiToJSON)),
        'slideDeck': DealSlideDeckOpenApiToJSON(value.slideDeck),
        'investors': value.investors,
        'termsTags': value.termsTags,
        'videoYoutubeCode': value.videoYoutubeCode,
    };
}

