/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealPubliclyAvailableDataPointOpenApi = {
    Name: 'NAME',
    Description: 'DESCRIPTION',
    Company: 'COMPANY',
    Categories: 'CATEGORIES',
    Geography: 'GEOGRAPHY',
    Logos: 'LOGOS',
    Stage: 'STAGE',
    Industry: 'INDUSTRY',
    TargetTicketSize: 'TARGET_TICKET_SIZE',
    FinalDeadline: 'FINAL_DEADLINE',
    InvestedAmount: 'INVESTED_AMOUNT',
    Investors: 'INVESTORS',
    TermsTags: 'TERMS_TAGS',
    Tiles: 'TILES',
    SlideDeck: 'SLIDE_DECK',
    VideoYoutubeCode: 'VIDEO_YOUTUBE_CODE'
} as const;
export type DealPubliclyAvailableDataPointOpenApi = typeof DealPubliclyAvailableDataPointOpenApi[keyof typeof DealPubliclyAvailableDataPointOpenApi];


export function DealPubliclyAvailableDataPointOpenApiFromJSON(json: any): DealPubliclyAvailableDataPointOpenApi {
    return DealPubliclyAvailableDataPointOpenApiFromJSONTyped(json, false);
}

export function DealPubliclyAvailableDataPointOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPubliclyAvailableDataPointOpenApi {
    return json as DealPubliclyAvailableDataPointOpenApi;
}

export function DealPubliclyAvailableDataPointOpenApiToJSON(value?: DealPubliclyAvailableDataPointOpenApi | null): any {
    return value as any;
}

