import {DealPublicOpenApi, DealWithSyndicatePublicOpenApi} from "../../../generated"
import {DealPublicType, DealWithSyndicatePublicType} from "./deal-public.type"
import {mapOpenApiToSyndicate} from "../../syndicate/syndicate.mapper"
import {mapOpenApiToCompany} from "../../company/company.mapper"
import {mapOpenApiToDealCategory} from "../../deal-category/deal-category.mapper"
import {mapOpenApiToDealGeography} from "../../deal-geography/deal-geography.mapper"
import {mapOpenApiToDealLogos, mapOpenApiToDealSlideDeck} from "../deal.mapper"
import {mapOpenApiToDealTile} from "../tile/tile.mapper"

export function mapOpenApiToDealWithSyndicatePublic(dealWithSyndicatePublic: DealWithSyndicatePublicOpenApi): DealWithSyndicatePublicType {
    return {
        deal: mapOpenApiToDealPublic(dealWithSyndicatePublic.deal),
        syndicate: mapOpenApiToSyndicate(dealWithSyndicatePublic.syndicate)
    }
}

export function mapOpenApiToDealPublic(dealPublic: DealPublicOpenApi): DealPublicType {
    return new DealPublicType({
        id: dealPublic.id,
        name: dealPublic.name,
        description: dealPublic.description,
        company: dealPublic.company && mapOpenApiToCompany(dealPublic.company),
        categories: dealPublic.categories && dealPublic.categories.map(c => mapOpenApiToDealCategory(c)),
        geography: dealPublic.geography && mapOpenApiToDealGeography(dealPublic.geography),
        stage: dealPublic.stage,
        outcome: dealPublic.outcome,
        waitingState: dealPublic.waitingState,
        industry: dealPublic.industry,
        finalDeadline: dealPublic.finalDeadline,
        minTarget: dealPublic.minTarget,
        maxTarget: dealPublic.maxTarget,
        minTicket: dealPublic.minTicket,
        currency: dealPublic.currency,
        investedAmount: dealPublic.investedAmount,
        logos: dealPublic.logos && mapOpenApiToDealLogos(dealPublic.logos),
        tiles: dealPublic.tiles && dealPublic.tiles.map(mapOpenApiToDealTile),
        slideDeck: dealPublic.slideDeck && mapOpenApiToDealSlideDeck(dealPublic.slideDeck),
        videoYoutubeCode: dealPublic.videoYoutubeCode,
        investors: dealPublic.investors,
        termsTags: dealPublic.termsTags,
        published: dealPublic.published
    })
}