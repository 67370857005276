import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import React, {FunctionComponent, useEffect} from "react"
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {useApp} from "../../../tech/app/context/app.context"
import {useQueries, useQueryClient} from "react-query"
import {QUERY__DEAL_WITH_INVESTMENT, QUERY__DEALS, QUERY_KEY__DEAL_WITH_INVESTMENT} from "../deal.query"
import {
    isSyncingDocument,
    removeSyncingDocumentParameterIfNecessary
} from "../../investment/pending/card/sign-documents/sign-documents.util"
import {QUERY__DEAL_OVERVIEW__DEALS} from "../overview.config"
import {DealOnboardingDependentWithInvestmentType} from "../onboarding-dependent.type"
import {isErroneous, showLoadingAnimation} from "../../../tech/query/query.util"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Breadcrumb from "../../../tech/breadcrumb/breadcrumb.component"
import {DEALS, ONBOARDING} from "../../../paths"
import {assembleSingleDealBreadcrumb} from "./single.util"
import PendingInvestmentCard from "../../investment/pending/card/card.component"
import DealSummary from "./summary/summary.component"
import InvestmentTimeline from "../../investment/timeline/timeline.component"
import {determineTimelineItems} from "../../investment/timeline/timeline.util"
import DealSlideDeck from "./slide-deck/slide-deck.component"
import DealAbout from "./about/about.component"
import DealTiles from "./tiles/tiles.component"
import DealQa from "./qa/qa.component"
import DealInvestButton from "./invest-button/invest-button.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {RouteData} from "../../../tech/routing/route.type"
import {isWhiteBackground, shouldRedirectToOnboarding} from "../../../tech/routing/authenticated/authenticated.util"
import Layout from "../../../tech/layout/layout.component"
import {DealType} from "../deal.type"
import DealDisclaimer from "./disclaimer/disclaimer.component"
import SyndicateBanner from "../../syndicate/banner/banner.component"
import {getSyndicateOfDeal} from "../../syndicate/syndicate.util"
import {showBreadcrumb} from "../../../tech/breadcrumb/breadcrumb.util"
import FixedContainer from "../../../tech/layout/fixed/fixed.component"
import styles from "./authenticated.module.sass"
import DealVideo from "./video/video.component"
import DealDocumentsTile from "./documents/documents.component"

type DealSingleAuthenticatedProps = {
    onboarding: InvestorOnboardingType
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const DealSingleAuthenticated: FunctionComponent<DealSingleAuthenticatedProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    let { id } = useParams()
    const app = useApp()
    const queryClient = useQueryClient()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (shouldRedirectToOnboarding(onboarding!, location.pathname)) {
            navigate(ONBOARDING)
        }
    }, [onboarding, location.pathname, navigate])

    const result = useQueries([
        QUERY__DEAL_WITH_INVESTMENT(
            app.fetchClient,
            {
                keyFunction: QUERY_KEY__DEAL_WITH_INVESTMENT,
                parameters: { id: id! }
            },
            true,
            isSyncingDocument(searchParams) ? 2000 : undefined
        ),
        QUERY__DEALS(
            app.fetchClient,
            queryClient,
            QUERY__DEAL_OVERVIEW__DEALS,
        )
    ])
    const resultDeal = (result[0].data) as DealOnboardingDependentWithInvestmentType | undefined
    const resultDeals = (result[1].data) as DealOnboardingDependentWithInvestmentType[] | undefined

    removeSyncingDocumentParameterIfNecessary(
        resultDeal?.investmentWithPaymentDetails?.investment,
        searchParams,
        setSearchParams
    )

    const withBreadcrumb = resultDeals && showBreadcrumb(resultDeals, onboarding)

    return (
        <Layout
            onboarding={onboarding!}
            openDeals={openDeals!}
            relevantRoutes={relevantRoutes}
            whiteBackground={isWhiteBackground(location.pathname)}
        >
            {showLoadingAnimation(result) && <LoadingDots/>}
            {resultDeal && resultDeals && (
                <div className={styles.single}>
                    {withBreadcrumb && (
                        <Breadcrumb
                            backLink={DEALS}
                            elements={assembleSingleDealBreadcrumb(resultDeal, location)}
                        />
                    )}
                    <PendingInvestmentCard
                        investmentWithPaymentDetails={resultDeal.investmentWithPaymentDetails}
                        onboarding={onboarding}
                        position="MOBILE"
                    />
                    <SyndicateBanner
                        syndicate={getSyndicateOfDeal(resultDeal.deal, onboarding)}
                        placement="MOBILE"
                    />
                    <div className={styles.container}>
                        <div className={styles.right}>
                            {resultDeal.investmentWithPaymentDetails ? (
                                <>
                                    <DealSummary
                                        deal={resultDeal.deal}
                                        investment={resultDeal.investmentWithPaymentDetails?.investment}
                                        onboarding={onboarding}
                                    />
                                    <div className={styles.timeline}>
                                        <InvestmentTimeline items={determineTimelineItems(
                                            onboarding,
                                            resultDeal.investmentWithPaymentDetails.investment
                                        )}/>
                                    </div>
                                </>
                            ) : (
                                <FixedContainer top={withBreadcrumb ? 174 : 120}>
                                    <DealSummary
                                        deal={resultDeal.deal}
                                        onboarding={onboarding}
                                    />
                                </FixedContainer>
                            )}
                        </div>
                        <div className={styles.left}>
                            <SyndicateBanner
                                syndicate={getSyndicateOfDeal(resultDeal.deal, onboarding)}
                                placement="DESKTOP"
                            />
                            <PendingInvestmentCard
                                investmentWithPaymentDetails={resultDeal.investmentWithPaymentDetails}
                                onboarding={onboarding}
                                position="DESKTOP"
                            />
                            <DealDisclaimer/>
                            <DealSlideDeck slideDeck={resultDeal.deal instanceof DealType ? resultDeal.deal.slideDeck : undefined}/>
                            <DealVideo deal={resultDeal.deal}/>
                            <DealAbout description={resultDeal.deal.description}/>
                            <DealTiles tiles={resultDeal.deal instanceof DealType ? resultDeal.deal.tiles : undefined}/>
                            <DealDocumentsTile
                                deal={resultDeal.deal}
                                investment={resultDeal.investmentWithPaymentDetails?.investment}
                            />
                            <DealQa deal={resultDeal.deal}/>
                        </div>
                    </div>
                    <DealInvestButton
                        deal={resultDeal.deal}
                        investment={resultDeal.investmentWithPaymentDetails?.investment}
                        onboarding={onboarding}
                        position="MOBILE_ACTION"
                    />
                </div>
            )}
            <>
                {isErroneous(result) && (
                    <Alert
                        type={AlertType.WARNING}
                        text="You don't have access to this deal yet."
                    />
                )}
            </>
        </Layout>
    )
}

export default DealSingleAuthenticated