import React from "react"
import ReactDOM from "react-dom/client"
import {QueryClientProvider} from "react-query"
import {BrowserRouter} from "react-router-dom"
import {init as initFullStory} from '@fullstory/browser'
import reportWebVitals from "./reportWebVitals"
import {sendToVercelAnalytics} from "./vitals"
import App from "./tech/app/App"
import AppContextProvider from "./tech/app/context/context.provider"
import {QUERY_CLIENT} from "./tech/query/query.client"
import AnalyticsWrapper from "./tech/app/analytics/analytics.component"
import {ReactQueryDevtools} from "react-query/devtools"
import {getEnvironment} from "./tech/environment/environment.util"
import {Environment} from "./tech/environment/environment.enum"
import ScrollToTop from "./tech/app/scroll-to-top.component"
import "./index.sass"

initFullStory({ orgId: "o-1VS0R8-na1" })

const env = getEnvironment()
if (env === Environment.LOCAL) {
    document.title = "[LOCAL] NonPublic"
}
if (env === Environment.DEVELOP || env === Environment.PREVIEW) {
    document.title = "[INT] NonPublic"
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <React.StrictMode>
      <AnalyticsWrapper>
          <QueryClientProvider client={QUERY_CLIENT}>
              <AppContextProvider>
                  <BrowserRouter>
                      <ScrollToTop>
                          <App/>
                      </ScrollToTop>
                  </BrowserRouter>
              </AppContextProvider>
              <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
      </AnalyticsWrapper>
  </React.StrictMode>
)

reportWebVitals(sendToVercelAnalytics)