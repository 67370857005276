import React, {FunctionComponent} from "react"
import {DashboardType} from "../../dashboard.type"
import InvestorDashboardTile from "../../tile/tile.component"
import {LineChartLineType} from "../../../../../tech/chart/line/line.type"
import LineChart from "../../../../../tech/chart/line/line.component"
import InvestorDashboardCurrentValueTooltip from "./tooltip/tooltip.component"

type InvestorDashboardSummaryValueProps = {
    dashboard: DashboardType
}

const InvestorDashboardSummaryValue: FunctionComponent<InvestorDashboardSummaryValueProps> = ({ dashboard }) => {
    if (!hasEnoughDataPoints(dashboard)) {
        return <></>
    }

    const lines: LineChartLineType[] = []
    lines.push({
        id: "value-current-value",
        label: "Current Value",
        points: dashboard.summary.performance.unrealized.map(p => ({
            date: p.date,
            value: p.value,
            description: p.description,
            preventHover: p.artificial,
        })),
        zIndex: 1
    })
    lines.push({
        id: "value-invested-amount",
        label: "Invested Amount",
        points: dashboard.summary.performance.invested.map(p => ({
            date: p.date,
            value: p.value,
            description: p.description,
            preventHover: p.artificial,
        })),
        zIndex: 2
    })
    if (dashboard.summary.performance.realized) {
        lines.push({
            id: "value-realized-value",
            label: "Realized Value",
            points: dashboard.summary.performance.realized.map(p => ({
                date: p.date,
                value: p.value,
                description: p.description,
                preventHover: p.artificial,
            })),
            zIndex: 3
        })
    }

    return (
        <InvestorDashboardTile
            title="Value"
            tooltip={<InvestorDashboardCurrentValueTooltip/>}
        >
                <LineChart
                    currency={dashboard.currency}
                    height={window.innerWidth > 768 ? 320 : 215}
                    lines={lines}
                />
        </InvestorDashboardTile>
    )
}

export default InvestorDashboardSummaryValue

function hasEnoughDataPoints(dashboard: DashboardType): boolean {
    const performance = dashboard.summary.performance
    return performance.invested.length > 1
        && performance.unrealized.length > 1
}